
window.onload = function () {
	scrollactions.init();
}
// window.onresize = function () {
	// console.log("window onresize - it works")
	// var screenwidth = $(window).outerWidth()
	// console.log('screenwidth = ' + screenwidth);
	// mobileFilter.init(); 
// };
 
