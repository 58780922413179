const scrollactions = {
	init: function () {
		
		// var headerHeight = document.querySelector("header").clientHeight;
		// document.querySelector(".contentwrapper").style.marginTop = headerHeight + "px"; 
		
		// var header = document.querySelector("header")

		function scrollToTop() {
			if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
				// document.getElementById("backTop").className = "";
				// header.classList.add("shrink")
			} else {
				// document.getElementById("backTop").className = "masked";
				// header.classList.remove("shrink")
			}
		}

		window.onscroll = function () { 
			scrollToTop() 
		};

	}
}